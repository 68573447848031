import React from 'react';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MainViewLink from '../../../common/components/MainViewLink';
import GalleryTab from './GalleryTab';
import routes from '../../../common/routes';
import { MyListTabs } from './MyList';

type Props = {
  deleteContent: React.ReactNode;
  sortComponent: React.ReactNode;
  mylistSelectedTab: MyListTabs;
  handleChangeMylistTab: (tab: MyListTabs) => void;
  handleChangeDeleteMode?: (isDeleteMode: boolean) => void;
};
type State = {
  activeTabIndex: number;
  deleteMode: string;
  mylistSelectedTab: MyListTabs;
};

export default class MyListGalleryHeader extends React.Component<Props, State> {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.links = [
      // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.mylist}>
        お気に入り
      </MainViewLink>,
      // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.continueWatching}>
        視聴中
      </MainViewLink>,
      // @ts-ignore TS2322
      <MainViewLink className="active" to={routes.onRental}>
        購入
      </MainViewLink>,
    ];

    const routeHandler = context.routeHandler;
    // @ts-ignore TS2339
    const activeTabIndex = _.findIndex(this.links, linkComponent => {
      // @ts-ignore TS2339
      return routeHandler.path.match(linkComponent.props.to._regex) !== null;
    });
    this.handleChangeDeleteMode = this.handleChangeDeleteMode.bind(this);
    this.state = {
      activeTabIndex: activeTabIndex,
      deleteMode: '',
      mylistSelectedTab: routeHandler.query.tab === 'teams' ? 'teams' : 'programs',
    };
  }

  componentDidMount() {
    if (this.props.mylistSelectedTab) {
      this.setState({ mylistSelectedTab: this.props.mylistSelectedTab });
    }
  }

  handleChangeDeleteMode(deleteMode) {
    this.setState({ deleteMode });
    if (this.props.handleChangeDeleteMode) {
      this.props.handleChangeDeleteMode(deleteMode !== '');
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    const userInfo = this.context.getModelData('userInfo');
    // @ts-ignore TS2339
    const currentLink = this.links[this.state.activeTabIndex];
    const isCurrentContinueWatching = currentLink && routes.continueWatching._regex === currentLink.props.to._regex;
    const isMylist = currentLink && routes.mylist._regex === currentLink.props.to._regex;

    let deleteContent = null;
    // @ts-ignore TS2339
    if (this.props.deleteContent) {
      // @ts-ignore TS2339
      deleteContent = React.cloneElement(this.props.deleteContent, { onChangeDeleteMode: this.handleChangeDeleteMode });
    }

    let viewinghistoryBtn = (
      <MainViewLink className="btn btn-line white-color viewing-history btn-very-small" to={routes.viewingHistory}>
        視聴履歴はこちら
      </MainViewLink>
    );

    let galleryContentHeaderTab = (
      // @ts-ignore TS2339
      <div className="gallery-content-header__tab">
        <a
          className={classnames('gallery-content-header__tab__item', {
            select: this.state.mylistSelectedTab === 'programs',
          })}
          onClick={() => this.props.handleChangeMylistTab('programs')}
        >
          番組
        </a>
        <a
          className={classnames('gallery-content-header__tab__item', {
            select: this.state.mylistSelectedTab === 'teams',
          })}
          onClick={() => this.props.handleChangeMylistTab('teams')}
        >
          チーム
        </a>
      </div>
    );
    return (
      <React.Fragment>
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">マイリスト</span>
          </div>
          {!(browserInfo.isIOS || browserInfo.isAndroid) && this.props.sortComponent}
        </div>
        {/* @ts-ignore TS2322 */}
        <GalleryTab links={this.links} activeTabIndex={this.state.activeTabIndex} />
        {/* @ts-ignore TS2322 */}
        {((browserInfo.isIOS || browserInfo.isAndroid) && !!this.props.sortComponent) ||
        deleteContent ||
        isMylist ||
        isCurrentContinueWatching ? (
          <div className={classnames('gallery-content-header', `${this.state.deleteMode}`)}>
            <div className="button-box">
              {isMylist ? galleryContentHeaderTab : null}
              {isCurrentContinueWatching ? viewinghistoryBtn : null}
              {deleteContent}
            </div>
            {/* @ts-ignore TS2339 */}
            {(browserInfo.isIOS || browserInfo.isAndroid) && this.props.sortComponent}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
